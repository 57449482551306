import React, { Component } from 'react';
import './Education.css';
import './animation.css';
import EduIcon from './images/education.png';

class Education extends Component {
  render() {
    return (
      <div className="CardEdu">
        <img className='EduIcon' src={EduIcon} alt="Education" />
        <div className='EduInfo'>
            <div className='Degree'>B.I.S in Information Technology</div>
            <div className='Major'>Major Enterprise Software</div>
            <div className='School'>Suranaree University of Technology</div>
        </div>
      </div>
    );
  }
}

export default Education;

import React, { Component } from 'react';
import './BottomNav.css';
import './animation.css';
import { ReactComponent as Linkedin } from './images/social/linkedin.svg';
import { ReactComponent as Behance } from './images/social/behance.svg';
import { ReactComponent as Github } from './images/social/github.svg';
import { ReactComponent as YouTube } from './images/social/youtube.svg';
import { ReactComponent as Steam } from './images/social/steam.svg';

class BottomNav extends Component {
  render() {
    return (
      <footer className='footer'>
          <p>Copyright © 2021 Phatthranit Osman. All rights reserved.</p>
          <div className='Social'>
            <div className='Icon'><a href='https://www.linkedin.com/in/phatthranitosman/' target='_blank' rel="noopener noreferrer"><Linkedin /></a></div>
            <div className='Icon'><a href='https://www.behance.net/biruoh' target='_blank' rel="noopener noreferrer"><Behance /></a></div>
            <div className='Icon'><a href='https://github.com/therisaz' target='_blank' rel="noopener noreferrer"><Github /></a></div>
            <div className='Icon'><a href='https://www.youtube.com/channel/UCcplV7gIfruBm99es0nn0vw' target='_blank' rel="noopener noreferrer"><YouTube /></a></div>
            <div className='Icon'><a href='https://steamcommunity.com/id/biruoh/' target='_blank' rel="noopener noreferrer"><Steam /></a></div>
          </div>
      </footer>
    );
  }
}

export default BottomNav;

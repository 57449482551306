import React, { Component } from 'react';
import './Experiences.css';
import './animation.css';
import ExpDetails from './ExpDetails';

class Experiences extends Component {
    constructor(props) {
        super(props);
        this.state = {isToggle: false};
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(e) {
        this.setState({isToggle: !this.state.isToggle});
    }
    render() {
        return (
            <div className='CardExp'>
                <div className='ExpInfo'>
                    <button onClick={this.handleClick}>
                        <h3>Work Experiences</h3>
                        <span className={this.state.isToggle ? 'arrow-down': 'arrow-right'} ></span>
                    </button>
                    <div style={{display: this.state.isToggle ? 'block': 'none'}} className='container'>
                        <ExpDetails />
                    </div>
                </div>
            </div>
        );
    }
}

export default Experiences;
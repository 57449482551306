import React, { Component } from 'react';
import './SubProfile.css';
import './animation.css';
import { ReactComponent as Sketch } from './images/sketch.svg';
import { ReactComponent as XD } from './images/xd.svg';
import { ReactComponent as PS } from './images/ps.svg';
import { ReactComponent as AI } from './images/ai.svg';
import { ReactComponent as InVision } from './images/invision.svg';
import { ReactComponent as Code } from './images/code.svg';

class SubProfile extends Component {
  render() {
    return (
      <div className="CardDes">
        <div className="DesInfo">
          <div className="ProfileDes">
            <p>Has worked extensively within eCommerce, software production companies and tech startups producing creative illustration and trendsetting brand marketing for B2B and B2C with a specialist focus on front-end user interface design and has a comprehensive understanding of Sketch, InVision, Adobe Creative Cloud and the latest web technologies.</p>
            <p>Specializing in front-end technologies with experience within the development cycle for dynamic web projects in numerous programming languages including HTML, CSS, JavaScript and SQL.</p>
          </div>
          <div className='hl'></div>
          <div className='Skills'>
            <h2>Special Skills</h2>
            <div className='SkillIcon'>
              <div className='SketchIcon'><Sketch /></div>
              <div className='XDIcon'><XD /></div>
              <div className='PSIcon'><PS /></div>
              <div className='AIIcon'><AI /></div>
              <div className='InvisIcon'><InVision /></div>
              <div className='CodeIcon'><Code /></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SubProfile;

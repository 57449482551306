import React, { Component } from 'react';
import './Experiences.css';
import './animation.css';
import { ReactComponent as Dot } from './images/dot.svg';


class ExpDetails extends Component {
  render() {
    return (
      <div className='Exp'>
        <div className='Info'>
            <div className='Year'>2020</div>
            <div className='Dot'><Dot /></div>
            <div className='Details'>
                <div className='Position'>Digital Content Creator & Illustrator</div>
                <div className='Company'>Freelancer</div>
                <div className='Location'>Cork, Ireland</div>
                <div className='ExpDes'>
                  <p>
                  Created an artwork for Adobe Creative Residency Community Fund 2020's commission.
                  </p>
                  <p>
                  Created useful contents and tutorials on YouTube to helping people learn about design tools focusing on iPad.
                  </p>
                </div>
            </div>
            <div className='clear'></div>
        </div>

        <div className='Info'>
            <div className='Year'>2019</div>
            <div className='Dot'><Dot /></div>
            <div className='Details'>
                <div className='Position'>Senior UI/UX Graphic Designer</div>
                <div className='Company'>comico Thailand</div>
                <div className='Location'>Bangkok, Thailand</div>
                <div className='ExpDes'>
                    <p>
                    Researched comico mobile application and user experiences to rectify various UI/UX issues and created new designs, wireframes, mockups and prototypes.
                    </p>
                    <p>
                    Collaborated with marketing team to created new ideas for the new design and produce co-promotional materials design for social media.
                    </p>
                </div>
            </div>
            <div className='clear'></div>
        </div>

        <div className='Info'>
            <div className='Year'>2019</div>
            <div className='Dot'><Dot /></div>
            <div className='Details'>
                <div className='Position'>UI/UX Designer & Illustrator</div>
                <div className='Company'>Freelancer</div>
                <div className='Location'>Nakhonratchasima, Thailand</div>
                <div className='ExpDes'>
                    <p>
                    Designed new ideas and created illustrations and collaborated with photographer to produce photographs and videos for microstock.
                    </p>
                    <p>
                    Created useful contents and tutorials on YouTube to helping people learn about design tools focusing on iPad.
                    </p>
                    <p>
                    Researched and collaborated with stakeholders to created new ideas for the new designs, mockups, illustrations and produce the Creation.de website with an emphasis on innovation for user-centered design and usability standards in remote work environment.
                    </p>
                </div>
            </div>
            <div className='clear'></div>
        </div>

        <div className='Info'>
            <div className='Year'>2018 - 2019</div>
            <div className='Dot'><Dot /></div>
            <div className='Details'>
                <div className='Position'>UI/UX Designer</div>
                <div className='Company'>The Make-It Co.,Ltd.</div>
                <div className='Location'>Chonburi, Thailand</div>
                <div className='ExpDes'>
                    <p>
                    Researched and created new designs, wireframes, mockups and prototypes for the Make A Bag application intended for mobile and tablet devices.
                    </p>
                    <p>
                    Created new ideas, validated workflows interfaces through iterations and collaborated with stakeholders to design and produce the application with an emphasis on innovation for user-centered design, usability standards and human factors testing.
                    </p>
                </div>
            </div>
            <div className='clear'></div>
        </div>

        <div className='Info'>
            <div className='Year'>2017 - 2019</div>
            <div className='Dot'><Dot /></div>
            <div className='Details'>
                <div className='Position'>UI/UX Designer</div>
                <div className='Company'>Crazy Factory Co.,Ltd.</div>
                <div className='Location'>Chonburi, Thailand</div>
                <div className='ExpDes'>
                    <p>
                    Researched company’s eCommerce website and user experiences to rectify various UI/UX issues and created new designs, wireframes, mockups and prototypes for Crazy Factory online store version 3 including the online product configurator to support both desktop and mobile resolutions.
                    </p>
                    <p>
                    Collaborated with developers and marketing teams to design and produce a website with a user-focused design and high usability standards.
                    </p>
                </div>
            </div>
            <div className='clear'></div>
        </div>

        <div className='Info'>
            <div className='Year'>2016 - 2017</div>
            <div className='Dot'><Dot /></div>
            <div className='Details'>
                <div className='Position'>Graphic Designer & Web Developer</div>
                <div className='Company'>Freelancer</div>
                <div className='Location'>Nakhonratchasima, Thailand</div>
                <div className='ExpDes'>
                    <p>
                    Designed and created visual concepts, graphic materials and illustrations for mobile cases and t-shirts.
                    </p>
                    <p>
                    Retouched promotional materials, photographs and produced traveling videos for social media and websites.
                    </p>
                    <p>
                    Planned, created, launched, and managed corporate websites with WordPress and Web-base, with emphasis on user-centered design, usability standards and human factors testing in remote work environment for Bangkok Entrepreneurs, Chaokoh Diving and BTC Cargo company.
                    </p>
                </div>
            </div>
            <div className='clear'></div>
        </div>

        <div className='Info'>
            <div className='Year'>2016</div>
            <div className='Dot'><Dot /></div>
            <div className='Details'>
                <div className='Position'>Graphic Designer & Social Media Manager</div>
                <div className='Company'>Bangkok Entrepreneurs Co.,Ltd.</div>
                <div className='Location'>Bangkok, Thailand</div>
                <div className='ExpDes'>
                    <p>
                    Participated in production of print and promotional items for a key division of the company under direction of the marketing department and created new design themes for social media, marketing and promotional materials such as advertisements on social media, backdrops, roll-up banners and presentations.
                    </p>
                    <p>
                    Experienced with developing and managing social media accounts and online marketing campaigns for corporate organizations.
                    </p>
                    <p>
                    Planned, created, launched, and organized websites for high-profile internet start-ups, The Thailand eCommerce Summit 2016, Thailand Mobile Summit 2016, Digital 16 and Thailand Startup Summit 2017 with WordPress and Web-base, with strong emphasis on user-centered design and usability standards.
                    </p>
                </div>
            </div>
            <div className='clear'></div>
        </div>

        <div className='Info'>
            <div className='Year'>2015</div>
            <div className='Dot'><Dot /></div>
            <div className='Details'>
                <div className='Position'>Designer (Illustrator Device)</div>
                <div className='Company'>Hexabone Antique</div>
                <div className='Location'>Tubli, Bahrain</div>
                <div className='ExpDes'>
                    <p>
                    Designed and created visual concepts, graphic materials and illustrations on devices to communicate ideas that inspire, inform and captivate consumers.
                    </p>
                    <p>
                    Developed the overall layout and production design in both print and digital media.
                    </p>
                </div>
            </div>
            <div className='clear'></div>
        </div>

        <div className='Info'>
            <div className='Year'>2015</div>
            <div className='Dot'><Dot /></div>
            <div className='Details'>
                <div className='Position'>UI/UX Designer</div>
                <div className='Company'>Anywhere 2 Go Co.,Ltd.</div>
                <div className='Location'>Bangkok, Thailand</div>
                <div className='ExpDes'>
                    <p>
                    Researched and created wireframes, mockups and prototypes for the Claim Di iOS application intended for mobile devices.
                    </p>
                    <p>
                    Created new ideas and collaborated with stakeholders to design and produce the application with an emphasis on innovation for user-centered design, usability standards and human factors testing.
                    </p>
                </div>
            </div>
            <div className='clear'></div>
        </div>

        <div className='Info'>
            <div className='Year'>2013 - 2015</div>
            <div className='Dot'><Dot /></div>
            <div className='Details'>
                <div className='Position'>Flash Programmer</div>
                <div className='Company'>We Create Game Co.,Ltd.</div>
                <div className='Location'>Bangkok, Thailand</div>
                <div className='ExpDes'>
                    <p>
                    Collaborated with designers to create simple and clean interfaces, intuitive interactions and experiences for development of e-learning applications and interaction kiosk in Action Script 3.</p>
                </div>
            </div>
            <div className='clear'></div>
        </div>
    </div>
    );
  }
}

export default ExpDetails;

import React, { Component } from 'react';
import './MainProfile.css';
import './animation.css';
import { ReactComponent as Quote } from './images/quote.svg';
import Badge from './images/badge.png';

class MainProfile extends Component {
    render() {
        return (
            <div className='CardProfile'>
                <div className='hl-top'></div>
                <div className='Headline'>
                    <div className='Quote'><Quote /></div>
                    <div className='HeadlineInfo'>
                        <h2>A creative UI/UX designer who is self-motivated, self-sufficient and comes to you with a strong background in multi-device design both website and mobile application.</h2>
                    </div>
                </div>
                <div className='vl'></div>
                <div className='PersonalInfo'>
                    <div className='Info'>
                        <img className='Badge' src={Badge} alt="Phatthranit Osman" />
                        <h1>Phatthranit Osman</h1>
                        <ul className='ContactInfo'>
                            <li className='Home'>2 Bridge Street
                            <br />Cork City, Cork
                            <br />Ireland</li>
                            <li className='Email'>hi@biruoh.com</li>
                            <li className='Tel'>+353 87 328 7654</li>
                        </ul>
                    </div>
                </div>
                <div className='hl-bottom'></div>
                <div className='clear'></div>
            </div>
        );
    }
}

export default MainProfile;

import React, { Component } from 'react';
import './App.css';
import MainProfile from './MainProfile';
import SubProfile from './SubProfile';
import Experiences from './Experiences';
import Education from './Education';
import Portfolio from './Portfolio';
import BottomNav from './BottomNav';

class App extends Component {
  render() {
    return (
      <div className="App">
        <MainProfile />
        <SubProfile />
        <Education />
        <Experiences />
        <Portfolio />
        <BottomNav />
      </div>
    );
  }
}

export default App;

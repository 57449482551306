import React, { Component } from 'react';
import './Portfolio.css';
import './animation.css';

class Portfolio extends Component {
    render() {
        return (
            <div className="CardPort">
                <div className='PortHeader'>
                    <h1>UI/UX</h1>
                    <h2>PORTFOLIO</h2>
                </div>
                <div className='PortInfo'>
                    <div className='Feature MakeABag'></div>
                    <div className='Details'>
                        <div className='PortTitle'>
                            <h1>Make A Bag's Application</h1>
                        </div>
                        <div className='PortDes'>Make A Bag is an innovative personal accessory application created with a clean interface and designed to incorporate a customisable configurator to produce a personal handbag or backpack with limitless combinations to bring your ideas to life. The application is fully compatible with iOS, Android, PC and Apple Mac.</div>
                        <div className='PortLink'>
                            <ul>
                                <li className='mPrototype'>
                                    <a href='https://invis.io/25PZ4LYA3ZY' target='_blank' rel="noopener noreferrer">Mobile Prototype</a>
                                </li>
                                <li className='tPrototype'>
                                    <a href='https://invis.io/H7PK0Z06D2R' target='_blank' rel="noopener noreferrer">Tablet Prototype</a>
                                </li>
                                <li className='CompanySite'>
                                    <a href='https://www.make-a-bag.com/' target='_blank' rel="noopener noreferrer">Make A Bag's Website</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='clear'></div>
                </div>
                <div className='PortInfo'>
                    <div className='Feature CFConfig'></div>
                    <div className='Details'>
                        <div className='PortTitle'>
                            <h1>Crazy Factory's Design It</h1>
                        </div>
                        <div className='PortDes'>“Design It” is an online product configurator that customises body piercings to add a personal touch. My objective was to analyse and rectify various UI/UX issues in multi-screen resolutions within the online product configurator and to redesign certain aspects with a clean and simple interface.</div>
                        <div className='PortLink'>
                            <ul>
                                <li className='mPrototype'>
                                    <a href='https://invis.io/EUNZEYLT7MA' target='_blank' rel="noopener noreferrer">Mobile Prototype</a>
                                </li>
                                <li className='tPrototype'>
                                    <a href='https://invis.io/9CNZF4H54ER' target='_blank' rel="noopener noreferrer">Desktop Prototype</a>
                                </li>
                                <li className='CompanySite'>
                                    <a href='https://www.crazy-factory.com/configurator/' target='_blank' rel="noopener noreferrer">Design It's Website</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='clear'></div>
                </div>
                <div className='PortInfo'>
                    <div className='Feature CFStore'></div>
                    <div className='Details'>
                        <div className='PortTitle'>
                            <h1>Crazy Factory's Online Store</h1>
                        </div>
                        <div className='PortDes'>Crazy Factory, which is one of the largest jewellery and piercing e-commerce stores globally attracts on average of 1.5 million customers every month. My objective was to analyse and rectify various UI/UX issues within the online store and to redesign certain aspects with a minimalistic, clean and simple interface.</div>
                        <div className='PortLink'>
                            <ul>
                                <li className='mPrototype'>
                                    <a href='https://xd.adobe.com/view/5e4e2616-6ab8-4368-4db7-36741fcd7944-287f/?fullscreen' target='_blank' rel="noopener noreferrer">Mobile Prototype</a>
                                </li>
                                <li className='tPrototype'>
                                    <a href='https://xd.adobe.com/view/6d2d5d5f-8e03-46f8-5b82-ce0772f4553b-1b1c/?fullscreen' target='_blank' rel="noopener noreferrer">Desktop Prototype</a>
                                </li>
                                <li className='CompanySite'>
                                    <a href='https://www.crazy-factory.com/' target='_blank' rel="noopener noreferrer">Crazy Factory's Website</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='clear'></div>
                </div>
                <div className='PortInfo'>
                    <div className='Feature Creations'></div>
                    <div className='Details'>
                        <div className='PortTitle'>
                            <h1>Creations.de's Website</h1>
                        </div>
                        <div className='PortDes'>Creations is a decent software and internet company. My objective was to analyse and to redesign certain aspects with a modern tech style, clean and simple interface.</div>
                        <div className='PortLink'>
                            <ul>
                                <li className='mPrototype'>
                                    <a href='https://invis.io/T6QZZGH29ZE' target='_blank' rel="noopener noreferrer">Website Mockup</a>
                                </li>
                                <li className='CompanySite'>
                                    <a href='https://creations.de/' target='_blank' rel="noopener noreferrer">Creations.de's Website</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='clear'></div>
                </div>
            </div>
        );
    }
}

export default Portfolio;
